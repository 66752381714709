<!--
 * @Description: 成为会员
 * @Author: zhou xian guang
 * @Date: 2025-3-12
 * @LastEditors: zhou xian guang
 * @LastEditTime:
 * @FilePath: /juno-web-ui/src/views/newHome/member.vue
-->
<template>
  <div class="homePage" style="background-color: #f8f8f8 !important;">
    <!-- Start Navbar Area -->
    <!--    <div class="float-card" v-if="isShowFloat">-->
    <!--      <div class="float-title">添加微信码联系企业</div>-->
    <!--      <img src="@/assets/home/custer.png" style="width: 180px;height: 180px">-->
    <!--      <div class="float-desc">需要深度解决方案或线下专业咨询-->
    <!--        请扫码-->
    <!--      </div>-->
    <!--      <img src="@/assets/home/custer-delete.png" class="custer-delete-icon" @click="onClose" />-->
    <!--    </div>-->
    <div class="header">
      <div class="navbar">
        <img src="~@/assets/home/logo.png" alt="" class="logo">
        <div class="navbar-item" :class="this.navbarKey === 0?'active':''" @click="onNavbar(0,'section0')">首页
          <div class="active"></div>
        </div>
        <div class="navbar-item" :class="this.navbarKey === 1?'active':''" @click="onNavbar(1,'section1')">常见问题及解决方案
        </div>
        <div class="navbar-item" :class="this.navbarKey === 2?'active':''" @click="onNavbar(2,'section2')">产品介绍
        </div>
        <div class="navbar-item" :class="this.navbarKey === 3?'active':''" @click="onNavbar(3,'section3')">产品优势
        </div>
        <div class="navbar-item" :class="this.navbarKey === 4?'active':''" @click="onNavbar(4,'section4')">
          合作推广方案
        </div>
        <div class="navbar-item" :class="this.navbarKey === 5?'active':''" @click="onNavbar(5,'section5')">关于我们
        </div>

        <div class="member-btn" STYLE="margin-left:145px">
          <img src="@/assets/home/icon-member.png" style="width: 20px;height: 20px">成为会员
        </div>
        <!--        <Menus v-if="hasLogin" />-->

        <Menus v-if="hasLogin" :showEntry="false" class="noLeft" />
        <div v-else class="login-btn" @click="toLogin">登录/注册</div>
      </div>
    </div>
    <!-- End Navbar Area -->
    <!-- Start Banner Area -->
    <div class="banner">
      <div class="banner-info">
        <div class="banner-title">一站式包装采购服务平台</div>
        <div class="banner-desc">包材包购、包材供应商管理、包材SRM | 安全、高效、公平</div>
      </div>
      <div class="cards">
        <div class="card-item">
          <div class="card-item-box">
            <img src="@/assets/home/sub-card.png" style="width: 60px;height: 60px">
            <div class="card-item-name">次卡</div>
            <div class="card-item-tag">询比价</div>
            <div>
              <div class="card-item-price-box">
                <div class="card-item-price">9.9</div>
                <div class="card-item-unit">元/次</div>
              </div>
              <div class=" card-btn card-btn" @click="toMeber(1)">立即使用</div>
            </div>
          </div>
          <div class="line24"></div>
          <div style="margin-bottom: 120px">
            <div class="member-info">次卡·会员权益</div>
            <div class="member-tag-box">
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">发布需求功能
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">查看报价列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">公共供应商库
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-no.png" class="meber-img">无发起订单功能
              </div>
            </div>
          </div>
        </div>

        <div class="card-item">
          <div class="card-item-box">
            <img src="@/assets/home/operation-member.png" style="width: 60px;height: 60px">
            <div class="card-item-name">运营版·会员</div>
            <div class="card-item-tag">询比价及框架合同管理</div>
            <div>
              <div class="card-item-price-box">
                <div class="card-item-price">300</div>
                <div class="card-item-unit">元/月起</div>
              </div>
              <div class=" card-btn card-btn1" @click="toMeber(2)">立即开通</div>
            </div>
          </div>
          <div class="line24"></div>
          <div style="margin-bottom: 120px">
            <div class="member-info">运营版·会员权益</div>
            <div class="member-tag-box">
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">发布需求功能
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">查看报价列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">框架合同列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">公共供应商库
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-no.png" class="meber-img">无发起订单功能
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-item-box">
            <img src="@/assets/home/pro-member.png" style="width: 60px;height: 60px">
            <div class="card-item-name">专业版·会员</div>
            <div class="card-item-tag">采购全流程管理</div>

            <div class="card-item-price-box">
              <div class="card-item-price">600</div>
              <div class="card-item-unit">元/月起</div>
            </div>
            <div class="card-btn card-btn2" @click="toMeber(3)">立即开通</div>
          </div>
          <div class="line24"></div>
          <div style="margin-bottom: 120px">
            <div class="member-info">专业版·会员权益</div>
            <div class="member-tag-box">
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">发布需求功能
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">查看报价列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">框架合同列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">订单列表
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">公共供应商库
              </div>
              <div class="member-tag"><img src="@/assets/home/meber-no.png" class="meber-img">无下载表功能
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-item-box">
            <img src="@/assets/home/enterprise-member.png" style="width: 60px;height: 60px">
            <div class="card-item-name">企业版·会员</div>
            <div class="card-item-tag">全流程管理+报表+2 个子账号</div>

            <div class="card-item-price-box">
              <div class="card-item-price">10,000</div>
              <div class="card-item-unit">元/年</div>
            </div>
            <div class="card-btn card-btn3" @click="toMeber(4)">立即开通</div>
          </div>
          <div class="line24"></div>
          <div style="margin-bottom: 120px">
            <div class="member-info">企业版·会员权益</div>
            <div class="member-tag-box">
              <div class="member-tag"><img src="@/assets/home/meber-have.png" class="meber-img">所有功能
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->
    <!-- Start Pop-up box  -->

    <a-modal v-model="isMemberModel" :footer="null" :width="880" class="content-Model" :closable="false">
      <div class="model-Member">
        <img src="@/assets/home/member-close.png" class="member-close" @click="onCloseMember">
        <div class="model-tabs">
          <div :class="modelIndex==1?'model-tab active':'model-tab'" @click="toTab(1)">
            <div style="display: flex;align-items: center;gap: 8px;"><img src="@/assets/home/sub-card-min.png"
                                                                          class="model-min-img">
              <div class="model-title" style="width: 32px !important">次卡</div>
            </div>
            <div class="model-desc">适合个人短频使用</div>
          </div>
          <div :class="modelIndex==2?'model-tab active':'model-tab'" @click="toTab(2)">
            <div style="display: flex;align-items: center;gap: 8px"><img src="@/assets/home/oper-version-min.png"
                                                                         class="model-min-img">
              <div class="model-title">运营版·会员</div>
            </div>
            <div class="model-desc">辅助文案说明辅助文案说明</div>
          </div>
          <div :class="modelIndex==3?'model-tab active':'model-tab'" @click="toTab(3)">
            <div style="display: flex;align-items: center;gap: 8px"><img src="@/assets/home/pro-edition-min.png"
                                                                         class="model-min-img">
              <div class="model-title">专业版·会员</div>
            </div>
            <div class="model-desc">采购全流程管理</div>
          </div>
          <div :class="modelIndex==4?'model-tab active':'model-tab'" @click="toTab(4)">
            <div style="display: flex;align-items: center;gap: 8px; width: 144px;"><img
              src="@/assets/home/enter-edition-min.png"
              class="model-min-img">
              <div class="model-title">企业版·会员</div>
            </div>
            <div class="model-desc">辅助文案说明辅助文案说明</div>
          </div>
        </div>
        <div class="model-content" v-if="modelIndex==1 ||modelIndex==2">
          <div v-if="modelIndex==1" class="content-box">
            <div class="center-box">
              <div class="center-top">
                <div class="center-standing">您当前身份为:
                  <div class="center-standing-tip">普通用户</div>
                </div>
                <div class="center-member-boxs">
                  <div class="center-member-box">
                    <div class="member-name">单次</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">9.9</div>
                    </div>
                    <img src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                </div>
                <div class="center-privilege">功能特权</div>
                <div class="center-privilege-item" style="gap: 48px">
                  <div class="privilege-item"><img src="@/assets/home/member-contacts.png">人脉库查询</div>
                  <div class="privilege-item"><img src="@/assets/home/member-inquiry-rate.png">询比价</div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付方式</div>
                  <div class="member-referrer-right">
                    <div class="wx-payment" @click="toPayWay(1)">
                      <img src="@/assets/wx-pay.png" class="wx-img">
                      微信
                      <img src="@/assets/inquiry-type.png" class="wx-inquiry">
                    </div>
                    <div @click="toPayWay(2)" class="ex-payment">企业对公转账 <img src="@/assets/home/member-right.png"
                                                                                   class="ex-img"></div>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">推荐人</div>
                  <div class="member-referrer-right">
                    <a-input style="width: 248px" placeholder="请输入推荐人ID"></a-input>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付金额</div>
                  <div class="member-referrer-right" style="display: flex;gap: 4px;align-items: end">
                    <div class="pay-symbol">¥</div>
                    <div class="pay-price">9.9</div>
                  </div>
                </div>
                <div class="pay-btn-box">
                  <div class=" pay-btn " @click="toPay('ordinary')">立即购买</div>
                </div>
              </div>
              <div class="center-bottom">
                <div class="bottom-item"><img src="@/assets/home/member-invoice.png" style="width: 16px;height: 16px">
                  电子发票
                </div>
                <div class="bottom-item">
                  <a-tooltip placement="top" :getPopupContainer="getPopupContainer">
                    <template slot="title">
                      <div
                        style="display: flex;flex-direction: column;gap: 4px;justify-content: center;align-items: center;">
                        <img src="@/assets/home/weChat-work.png" style="width: 140px;height: 140px">
                        <div class="tip-desc">添加微信联系企业</div>
                      </div>
                    </template>
                    <img src="@/assets/home/member-wx.png" style="width: 21px;height: 21px" />需要深度解决方案或线下专业咨询,请联系客服
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="left-box">
              <div class="left-item">
                <img src="@/assets/home/sub-card.png">
                <div class="left-item-title">次卡</div>
                <div class="left-item-desc">询价比</div>
                <div class="left-box-line"></div>
                <div class="left-item-ex-desc">
                  <div class="desc-title">权益</div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    发布需求功能
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    查看报价列表
                  </div>

                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    公共供应商库
                  </div>

                </div>
              </div>
            </div>
            <!--            <div class="right-box">-->
            <!--              <div class="right-top">-->
            <!--                <div style="display: flex;align-items: flex-end">-->
            <!--                  <div class="right-top-syl">￥</div>-->
            <!--                  <div class="right-top-price">9.9</div>-->
            <!--                </div>-->
            <!--                <div class="right-tag">-->
            <!--                  <a-tooltip placement="bottomRight" :getPopupContainer="getPopupContainer">-->
            <!--                    <template slot="title">-->
            <!--                      <div style="display: flex;flex-direction: column;">-->
            <!--                        <div class="total-desc">商品总计</div>-->
            <!--                        <div class="total-price">次卡 — ¥9.9/次</div>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <div style="display: flex;justify-content: center;align-items: center;">-->
            <!--                      明细 <img src="@/assets/home/member-right.png">-->
            <!--                    </div>-->
            <!--                  </a-tooltip>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <img src="@/assets/home/collection-code.png" class="collection-code">-->
            <!--                </div>-->
            <!--                <div class="right-desc" style="padding: 8px 0px">使用 微信 扫码支付</div>-->
            <!--                <div class="right-desc">支付即视为你同意 <span style="color: #FF6026">相关协议</span> 条例</div>-->
            <!--              </div>-->
            <!--              <div class="forPublic-btn" @click="toCorporateTransfer">我要对公转账</div>-->
            <!--            </div>-->

          </div>
          <div v-if="modelIndex==2" class="content-box">


            <div class="center-box">
              <div class="center-top">
                <div class="center-standing">您当前身份为:
                  <div class="center-standing-tip">普通用户</div>
                </div>
                <div class="center-member-boxs">
                  <div :class=" cardMeber==1?'center-member-box':' center-member-box center-member-box-default' "
                       @click="tabMeberCard(1)">
                    <div class="member-name">1个月</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">300</div>
                    </div>
                    <img v-if="cardMeber==1" src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                  <div :class=" cardMeber==2?'center-member-box':' center-member-box center-member-box-default' "
                       @click="tabMeberCard(2)">
                    <div class="member-name">1年</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">3000</div>
                    </div>
                    <img v-if="cardMeber==2" src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                </div>
                <div class="center-privilege">功能特权</div>
                <div class="center-privilege-item" style="gap: 48px">
                  <div class="privilege-item"><img src="@/assets/home/member-contacts.png">人脉库查询</div>
                  <div class="privilege-item" style="min-width: 112px !important;"><img
                    src="@/assets/home/member-inquiry-rate.png">询比价
                  </div>
                  <div class="privilege-item" style="width: 160px !important;"><img
                    src="@/assets/home/frame-contract.png">框架合同管理
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付方式</div>
                  <div class="member-referrer-right">
                    <div class="wx-payment" @click="toPayWay(1)">
                      <img src="@/assets/wx-pay.png" class="wx-img">
                      微信
                      <img src="@/assets/inquiry-type.png" class="wx-inquiry">
                    </div>
                    <div @click="toPayWay(2)" class="ex-payment">企业对公转账 <img src="@/assets/home/member-right.png"
                                                                                   class="ex-img"></div>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">推荐人</div>
                  <div class="member-referrer-right">
                    <a-input style="width: 248px" placeholder="请输入推荐人ID"></a-input>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付金额</div>
                  <div class="member-referrer-right" style="display: flex;gap: 4px;align-items: end">
                    <div class="pay-symbol">¥</div>
                    <div class="pay-price" v-if="cardMeber==1">300</div>
                    <div class="pay-price" v-if="cardMeber==2">3000</div>

                  </div>
                </div>
                <div class="pay-btn-box">
                  <div class=" pay-btn " @click="toPay('operating')">立即购买</div>
                </div>
              </div>
              <div class="center-bottom">
                <div class="bottom-item"><img src="@/assets/home/member-invoice.png" style="width: 16px;height: 16px">
                  电子发票
                </div>
                <div class="bottom-item">
                  <a-tooltip placement="top" :getPopupContainer="getPopupContainer">
                    <template slot="title">
                      <div
                        style="display: flex;flex-direction: column;gap: 4px;justify-content: center;align-items: center">
                        <img src="@/assets/home/weChat-work.png" style="width: 140px;height: 140px">
                        <div class="tip-desc">添加微信联系企业</div>
                      </div>
                    </template>
                    <img src="@/assets/home/member-wx.png" style="width: 21px;height: 21px" />需要深度解决方案或线下专业咨询,请联系客服
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="left-box left-box1">
              <div class="left-item" style="display: unset">
                <img src="@/assets/home/operation-member.png" class="member-img">
                <div class="left-item-title">运营版·会员</div>
                <div class="left-item-desc">询比价及框架合同管理</div>
                <div class="left-box-line"></div>
                <div class="left-item-ex-desc">
                  <div class="desc-title">权益</div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    发布需求功能
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    查看报价列表
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    框架合同列表
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    公共供应商库
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="right-box">-->
            <!--              <div class="right-top">-->
            <!--                <div style="display: flex;align-items: flex-end">-->
            <!--                  <div class="right-top-syl">￥</div>-->
            <!--                  <div class="right-top-price" v-if="cardMeber==1">300</div>-->
            <!--                  <div class="right-top-price" v-if="cardMeber==2">3000</div>-->
            <!--                </div>-->
            <!--                <div class="right-tag">-->
            <!--                  <a-tooltip placement="bottomRight" :getPopupContainer="getPopupContainer">-->
            <!--                    <template slot="title">-->
            <!--                      <div style="display: flex;flex-direction: column;">-->
            <!--                        <div class="total-desc">商品总计</div>-->
            <!--                        <div class="total-price" style="display: flex" >-->
            <!--                          <div v-if="cardMeber==1" style="gap: 24px;display: flex;justify-content: space-between"><div>运营版·会员  —  (1个月)</div><div>¥300.00</div></div>-->
            <!--                          <div v-if="cardMeber==2" style="gap: 24px;display: flex;justify-content: space-between"><div>运营版·会员  —  (1年)</div><div>¥3000.00</div></div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <div style="display: flex;justify-content: center;align-items: center;">-->
            <!--                      明细 <img src="@/assets/home/member-right.png">-->
            <!--                    </div>-->
            <!--                  </a-tooltip>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <img src="@/assets/home/collection-code.png" class="collection-code">-->
            <!--                </div>-->
            <!--                <div class="right-desc" style="padding: 8px 0px">使用 微信 扫码支付</div>-->
            <!--                <div class="right-desc">支付即视为你同意 <span style="color: #FF6026">相关协议</span> 条例</div>-->
            <!--              </div>-->
            <!--              <div class="forPublic-btn" @click="toCorporateTransfer">我要对公转账</div>-->
            <!--            </div>-->
          </div>

        </div>
        <div class="model-content1" v-if="modelIndex==3">
          <div class="content-box">


            <div class="center-box">
              <div class="center-top center-top1">
                <div class="center-standing">您当前身份为:
                  <div class="center-standing-tip">普通用户</div>
                </div>
                <div class="center-member-boxs">
                  <div :class=" cardMeber==3?'center-member-box':' center-member-box center-member-box-default' "
                       @click="tabMeberCard(3)">
                    <div class="member-name">1个月</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">600</div>
                    </div>
                    <img v-if="cardMeber==3" src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                  <div :class=" cardMeber==4?'center-member-box':' center-member-box center-member-box-default' "
                       @click="tabMeberCard(4)">
                    <div class="member-name">1年</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">6000</div>
                    </div>
                    <img v-if="cardMeber==4" src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                </div>
                <div class="center-privilege">功能特权</div>
                <div class="center-privilege-item" style="gap: 16px;">
                  <div class="privilege-item" style="width: 160px;margin-right: 24px"><img
                    src="@/assets/home/member-contacts.png">人脉库查询
                  </div>
                  <div class="privilege-item" style="width: 160px;margin-right: 24px"><img
                    src="@/assets/home/member-inquiry-rate.png">询比价
                  </div>
                  <div class="privilege-item" style="width: 160px;margin-right: 24px"><img
                    src="@/assets/home/frame-contract.png">框架合同管理
                  </div>
                  <div class="privilege-item" style="width: 192px"><img src="@/assets/home/member-order.png">订单生产流程管理
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付方式</div>
                  <div class="member-referrer-right">
                    <div class="wx-payment" @click="toPayWay(1)">
                      <img src="@/assets/wx-pay.png" class="wx-img">
                      微信
                      <img src="@/assets/inquiry-type.png" class="wx-inquiry">
                    </div>
                    <div @click="toPayWay(2)" class="ex-payment">企业对公转账 <img src="@/assets/home/member-right.png"
                                                                                   class="ex-img"></div>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">推荐人</div>
                  <div class="member-referrer-right">
                    <a-input style="width: 248px" placeholder="请输入推荐人ID"></a-input>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付金额</div>
                  <div class="member-referrer-right" style="display: flex;gap: 4px;align-items: end">
                    <div class="pay-symbol">¥</div>
                    <div class="pay-price" v-if="cardMeber==3">600</div>
                    <div class="pay-price" v-if="cardMeber==4">6000</div>

                  </div>
                </div>
                <div class="pay-btn-box">
                  <div class=" pay-btn " @click="toPay('major')">立即购买</div>
                </div>
              </div>
              <div class="center-bottom">
                <div class="bottom-item"><img src="@/assets/home/member-invoice.png" style="width: 16px;height: 16px">
                  电子发票
                </div>
                <div class="bottom-item">
                  <a-tooltip placement="top" :getPopupContainer="getPopupContainer">
                    <template slot="title">
                      <div
                        style="display: flex;flex-direction: column;gap: 4px;justify-content: center;align-items: center">
                        <img src="@/assets/home/weChat-work.png" style="width: 140px;height: 140px">
                        <div class="tip-desc">添加微信联系企业</div>
                      </div>
                    </template>
                    <img src="@/assets/home/member-wx.png" style="width: 21px;height: 21px" />需要深度解决方案或线下专业咨询,请联系客服
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="left-box left-box1 left-box2">
              <div class="left-item" style="display: unset">
                <img src="@/assets/home/pro-member.png" class="member-img">
                <div class="left-item-title">专业版·会员</div>
                <div class="left-item-desc">采购全流程管理</div>
                <div class="left-box-line"></div>
                <div class="left-item-ex-desc">
                  <div class="desc-title">权益</div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    发布需求功能
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    查看报价列表
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    订单列表
                  </div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    公共供应商库
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="right-box">-->
            <!--              <div class="right-top">-->
            <!--                <div style="display: flex;align-items: flex-end">-->
            <!--                  <div class="right-top-syl">￥</div>-->
            <!--                  <div class="right-top-price" v-if="cardMeber==3">600</div>-->
            <!--                  <div class="right-top-price" v-if="cardMeber==4">6000</div>-->
            <!--                </div>-->
            <!--                <div class="right-tag">-->
            <!--                  <a-tooltip placement="bottomRight" :getPopupContainer="getPopupContainer">-->
            <!--                    <template slot="title">-->
            <!--                      <div style="display: flex;flex-direction: column;" v-if="cardMeber==3">-->
            <!--                        <div class="total-desc">商品总计</div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>专业版·会员  —  (1个月)</span><span>¥600.00</span></div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>赠/运营版·会员  —  (1个月)</span><span>¥300.00</span></div>-->
            <!--                        <div class="total-desc">优惠合计</div>-->
            <!--                        <div class="total-gift">-->
            <!--                          <div style="display: flex;align-items: center;">-->
            <!--                            <img src="@/assets/home/discount-icon.png" class="discount-icon">赠品优惠-->
            <!--                          </div>-->
            <!--                          <div class="discount-price">-¥300.00</div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                      <div style="display: flex;flex-direction: column;" v-if="cardMeber==4">-->
            <!--                        <div class="total-desc">商品总计</div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>专业版·会员  —  (1年)</span><span>¥6000.00</span></div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>赠/运营版·会员  —  (1年)</span><span>¥3000.00</span></div>-->
            <!--                        <div class="total-desc">优惠合计</div>-->
            <!--                        <div class="total-gift">-->
            <!--                          <div style="display: flex;align-items: center;">-->
            <!--                            <img src="@/assets/home/discount-icon.png" class="discount-icon">赠品优惠-->
            <!--                          </div>-->
            <!--                          <div class="discount-price">-¥3000.00</div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <div style="display: flex;justify-content: center;align-items: center;">-->
            <!--                      明细 <img src="@/assets/home/member-right.png">-->
            <!--                    </div>-->
            <!--                  </a-tooltip>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <img src="@/assets/home/collection-code.png" class="collection-code">-->
            <!--                </div>-->
            <!--                <div class="right-desc" style="padding: 8px 0px">使用 微信 扫码支付</div>-->
            <!--                <div class="right-desc">支付即视为你同意 <span style="color: #FF6026">相关协议</span> 条例</div>-->
            <!--              </div>-->
            <!--              <div class="forPublic-btn" @click="toCorporateTransfer">我要对公转账</div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="model-content2" v-if="modelIndex==4">
          <div class="content-box">
            <div class="center-box">
              <div class="center-top center-top1">
                <div class="center-standing">您当前身份为:
                  <div class="center-standing-tip">普通用户</div>
                </div>
                <div class="center-member-boxs">
                  <div class="center-member-box">
                    <div class="member-name">1年</div>
                    <div style="display: flex;align-items: flex-end;height: 39px">
                      <div class="member-symbol">￥</div>
                      <div class="member-price">10,000</div>
                    </div>
                    <img src="@/assets/inquiry-type.png" class="inquiry-type-img">
                  </div>
                </div>
                <div class="center-privilege">功能特权</div>
                <div class="center-privilege-item" style="gap: 16px">
                  <div class="privilege-item" style="width: 192px;margin-right: 24px"><img
                    src="@/assets/home/member-contacts.png">人脉库查询
                  </div>
                  <div class="privilege-item" style="width: 144px;margin-right: 24px"><img
                    src="@/assets/home/member-inquiry-rate.png">询比价
                  </div>
                  <div class="privilege-item" style="width: 160px;margin-right: 24px"><img
                    src="@/assets/home/frame-contract.png">框架合同管理
                  </div>
                  <div class="privilege-item" style="width: 192px;margin-right: 24px"><img
                    src="@/assets/home/member-order.png">订单生产流程管理
                  </div>
                  <div class="privilege-item" style="width: 144px;margin-right: 24px"><img
                    src="@/assets/home/report-forms.png">报表
                  </div>
                  <div class="privilege-item" style="width: 160px;margin-right: 24px"><img
                    src="@/assets/home/sub-account.png">企业子账号
                  </div>
                </div>

                <div class="online-report">
                  <img src="@/assets/home/gift-min.png">
                  <div class="report-desc">免费赠送「线下深度咨询和线上解决方案报告」</div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付方式</div>
                  <div class="member-referrer-right">
                    <div class="wx-payment" @click="toPayWay(1)">
                      <img src="@/assets/wx-pay.png" class="wx-img">
                      微信
                      <img src="@/assets/inquiry-type.png" class="wx-inquiry">
                    </div>
                    <div @click="toPayWay(2)" class="ex-payment">企业对公转账 <img src="@/assets/home/member-right.png"
                                                                                   class="ex-img"></div>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">推荐人</div>
                  <div class="member-referrer-right">
                    <a-input style="width: 248px" placeholder="请输入推荐人ID"></a-input>
                  </div>
                </div>
                <div class="member-referrer">
                  <div class="member-referrer-left">支付金额</div>
                  <div class="member-referrer-right" style="display: flex;gap: 4px;align-items: end">
                    <div class="pay-symbol">¥</div>
                    <div class="pay-price">10,000</div>
                  </div>
                </div>
                <div class="pay-btn-box">
                  <div class=" pay-btn " @click="toPay('enterprise')">立即购买</div>
                </div>
              </div>
              <div class="center-bottom">
                <div class="bottom-item"><img src="@/assets/home/member-invoice.png" style="width: 16px;height: 16px">
                  电子发票
                </div>
                <div class="bottom-item">
                  <a-tooltip placement="top" :getPopupContainer="getPopupContainer">
                    <template slot="title">
                      <div
                        style="display: flex;flex-direction: column;gap: 4px;justify-content: center;align-items: center">
                        <img src="@/assets/home/weChat-work.png" style="width: 140px;height: 140px">
                        <div class="tip-desc">添加微信联系企业</div>
                      </div>
                    </template>
                    <img src="@/assets/home/member-wx.png" style="width: 21px;height: 21px" />需要深度解决方案或线下专业咨询,请联系客服
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="left-box left-box1 left-box2 left-box3">
              <div class="left-item" style="display: unset">
                <img src="@/assets/home/enterprise-member.png" class="member-img">
                <div class="left-item-title">企业版·会员</div>
                <div class="left-item-desc">全流程管理+报表+2 个子账号</div>
                <div class="left-box-line"></div>
                <div class="left-item-ex-desc">
                  <div class="desc-title">权益</div>
                  <div class="desc-ex">
                    <div class="desc-circle"></div>
                    所有功能
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="right-box">-->
            <!--              <div class="right-top">-->
            <!--                <div style="display: flex;align-items: flex-end">-->
            <!--                  <div class="right-top-syl">￥</div>-->
            <!--                  <div class="right-top-price">10,000</div>-->
            <!--                </div>-->
            <!--                <div class="right-tag">-->
            <!--                  <a-tooltip placement="bottomRight" :getPopupContainer="getPopupContainer">-->
            <!--                    <template slot="title">-->
            <!--                      <div style="display: flex;flex-direction: column;">-->
            <!--                        <div class="total-desc">商品总计</div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>运营版·会员  —  (1个月)</span><span>¥300.00</span></div>-->
            <!--                        <div class="total-price" style="gap: 24px;display: flex;justify-content: space-between">-->
            <!--                          <span>赠/专业版·会员  —  (1个月)</span><span>¥600.00</span></div>-->
            <!--                        <div class="total-desc">优惠合计</div>-->
            <!--                        <div class="total-gift">-->
            <!--                          <div style="display: flex;align-items: center">-->
            <!--                            <img src="@/assets/home/discount-icon.png" class="discount-icon">赠品优惠-->
            <!--                          </div>-->
            <!--                          <div class="discount-price">-¥300.00</div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <div style="display: flex;justify-content: center;align-items: center;">-->
            <!--                      已优惠300元&nbsp;&nbsp;|&nbsp;&nbsp;明细 <img src="@/assets/home/member-right.png">-->
            <!--                    </div>-->
            <!--                  </a-tooltip>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <img src="@/assets/home/collection-code.png" class="collection-code">-->
            <!--                </div>-->
            <!--                <div class="right-desc" style="padding: 8px 0px">使用 微信 扫码支付</div>-->
            <!--                <div class="right-desc">支付即视为你同意 <span style="color: #FF6026">相关协议</span> 条例</div>-->
            <!--              </div>-->
            <!--              <div class="forPublic-btn" @click="toCorporateTransfer">我要对公转账</div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </a-modal>
    <!-- End Pop-up box -->
    <a-modal class="showContent" v-model="iswxPayShow" :width="320" :footer="null" :closable="false">
      <div class="collection-code-box">
        <img src="@/assets/home/member-close.png" class="member-close" @click="onClosewxPay">
        <div style="padding-bottom: 16px">
          <img src="@/assets/home/collection-code.png" style="width: 180px;height: 180px;" />
        </div>
        <span class="collection-code-desc" style="padding-bottom: 8px">使用 微信 扫码支付</span>
        <span class="collection-code-desc">支付即视为你同意<span style="color: #FF6026;padding: 0px 4px">相关协议</span>条例</span>
      </div>
    </a-modal>

    <a-modal class="showContent" title="温馨提示" v-model="isShowA" :width="560" @ok="handleOK" @cancel="handleClose">
      <div class="info-area">
        <img src="~@/assets/largeWarning.png" alt="" class="icon">
        <span class="text">确定选择对公转账方式进行付款？</span>
      </div>
    </a-modal>
    <a-modal class="showContent" title="对公转账" v-model="isShowAContent" :width="560"
             style="border-radius: 4px !important;">
      <div class="info-area" style="flex-direction: column;align-items: unset">
        <div class="info-head">
          <img src="@/assets/home/order-success.png">
          下单成功
        </div>
        <div class="info-desc">
          转账成功后请将汇款底单拍照或截图发送给客服确认汇款到账后即会为您开通相关会员,开通后可在个人中心索取发票
        </div>
        <div style="display: flex;justify-content: center">
          <div class="info-detail">
            <div class="detail-row detail-tag">每笔订单对应不同的25位收款账号，请勿重复打款</div>
            <div class="detail-row">
              <div class="detail-left">转账金额</div>
              <div class="detail-right">¥600.00</div>
            </div>
            <div class="detail-row">
              <div class="detail-left">收款公司名称</div>
              <div class="detail-right">上海对公账户有限公司</div>
            </div>

            <div class="detail-row">
              <div class="detail-left">银行账户</div>
              <div class="detail-right">3515 8888 8888 8888 8888</div>
            </div>
            <div class="detail-row">
              <div class="detail-left">收款方开户行</div>
              <div class="detail-right">中国银行上海支行</div>
            </div>

          </div>
        </div>
        <div class="info-footer"><img src="@/assets/home/waring-order.png">
          <div class="info-footer-desc">
            对公转账到账时间可能受银行处理时间影响，<span style="color: #FF6026">建议采用</span>在线支付请通过网银转账或自行到银行进行转账
          </div>
        </div>
      </div>
      <template #footer>
        <a-button key="submit" style="width: 514px;height: 38px;margin:6px 6px 6px 0px" type="primary" :loading="loading"
                  @click="handleCopy">
          复制转账信息
        </a-button>
      </template>
    </a-modal>
    <a-modal class="showContent" title="温馨提示" v-model="isShowB" :width="560">
      <div class="info-area" style=";align-items: flex-start">
        <img src="~@/assets/largeWarning.png" alt="" class="icon">
        <div style="display: flex;flex-direction: column;gap: 8px;align-items: flex-start">
          <span class="text">您当前已有待支付的续费或者升级订单</span>
          <span class="text"
                style="font-size: 14px;  font-weight: 400; color: #8C8C8C;">创建新订单将取消待支付的续费或升级订单</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import homeHeader from './modules/homeHeader.vue'
import SendMethods from '@/mixins/sendMethods.js'
import Elevator from '@/components/tools/elevator.vue'
import ChoosePackagingMaterial from '@/components/plugins/ChoosePackagingMaterial.vue'
import Menus from '@/components/tools/menus.vue'
import { mapState, mapGetters } from 'vuex'


export default {
  components: {
    Menus,
    homeHeader,
    Elevator,
    ChoosePackagingMaterial
  },
  mixins: [SendMethods],
  data() {
    return {
      navbarKey: null,
      navfloatKey: null,
      sections: [
        { id: 'section0', key: 0 },
        { id: 'section1', key: 1 },
        { id: 'section2', key: 2 },
        { id: 'section3', key: 3 },
        { id: 'section4', key: 4 },
        { id: 'section5', key: 5 }
      ],
      isShowFloat: true,
      isMemberModel: false,
      modelIndex: 1,
      cardMeber: 1,
      isShowA: false,
      isShowAContent: false,
      isShowB: false,
      customStyle: {
        color: 'red', // 例如，将文字颜色设置为红色
        backgroundColor: '#f0f0f0', // 设置背景颜色
        padding: '5px 10px' // 设置内边距
      },
      payMethod: 1,
      iswxPayShow: false
    }
  },
  computed: {
    ...mapState({
      hasLogin: state => state.user.isLogin,
      regionData: state => state.user.regionData
    })
  }, mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getPopupContainer(triggerNode) {
      return triggerNode.parentNode
    },
    handleScroll() {
      const scrollPosition = window.pageYOffset + 200 // 考虑到顶部固定导航栏的高度
      for (let i = this.sections.length - 1; i >= 0; i--) {
        const section = this.sections[i]
        const element = document.getElementById(section.id)
        if (element && scrollPosition >= element.offsetTop) {
          this.navbarKey = section.key
          break
        }
      }
    },
    handleJumpPage(url) {
      console.log(url)
      this.$router.push(url)
    },
    onNavbar(key, sectionId) {
      this.$router.push('/')
      // this.navbarKey = key
      // const element = document.getElementById(sectionId)
      // if (element) {
      //   element.scrollIntoView({
      //     behavior: 'smooth', // 平滑滚动
      //     block: 'start' // 滚动到元素顶部
      //   })
      // }

    }, onFloatNavTab(key) {
      this.navfloatKey = key
      if (this.navfloatKey == 4) {
        this.navbarKey = 0
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // 平滑滚动
          block: 'start' // 滚动到元素顶部
        })
      }
    },
    scrollToTop() {

    }, toLogin() {
      this.$router.push('/login')
    },
    toTry() {

      this.$router.push('/workSpace')
    },
    onClose() {
      this.isShowFloat = false
    }, toMeber(index) {
      this.modelIndex = index
      this.isMemberModel = true
    }, toTab(index) {
      this.modelIndex = index
      if (this.modelIndex == 2) {
        this.cardMeber = 1
      } else if (this.modelIndex == 3) {
        this.cardMeber = 3
      }
    }, tabMeberCard(index) {
      this.cardMeber = index
    }, toCorporateTransfer() {
      this.isShowA = true
      this.isMemberModel = false
    }, handleOK() {
      this.isShowAContent = true
    }, handleClose() {
      this.isShowA = false
    }, onCloseMember() {
      this.isMemberModel = false
    },
    toPayWay(type) {
      this.payMethod = type
      if(this.payMethod == 2) {
        this.isShowA = true
        this.isMemberModel = false
      }

    },
    toPay(type) {
      let payPrice = 0
      if (type == 'ordinary') {
        payPrice = 9.9
      } else if (type == 'operating') {
        if (this.cardMeber == 1) {
          payPrice = 300
        } else if (this.cardMeber == 2) {
          payPrice = 3000
        }
      } else if (type == 'major') {
        if (this.cardMeber == 3) {
          payPrice = 600
        } else if (this.cardMeber == 4) {
          payPrice = 6000
        }
      } else if (type == 'enterprise') {
        payPrice = 10000
      }
      this.iswxPayShow = true

    }, onClosewxPay() {
      this.iswxPayShow = false
    },handleCopy(){
      let copyStr=[{'收款公司名称':'上海对公账户有限公司'},{'银行账户':'35158888888888888888'},{'收款方开户行':'中国银行上海支行'}]
      const formattedStr = copyStr
        .map(item => Object.values(item)[0]) // 提取每个对象的值
        .join('\n');
      const _input = document.createElement("input"); // 直接构建input
      _input.value =formattedStr; // 设置内容
      document.body.appendChild(_input); // 添加临时实例
      _input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(_input); // 删除临时实例
      this.$message.success('复制成功')
    }
  }
}
</script>
<style lang="less" scoped>

.homePage {
  display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: column;
}

.header {
  background-color: #fff;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;

  .navbar {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    .member-btn {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      //align-items: center;
      padding: 8px 12px;
      background: linear-gradient(270deg, #2F2F2F 0%, #585857 100%);
      border-radius: 6px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      //line-height: 20px;
      color: #F2D2A3;
      box-sizing: border-box;
      margin-left: 88.33px;
      margin-right: 24px;
    }

    .login-btn {
      cursor: pointer;
      /* icon+文字/主要/click */
      white-space: nowrap;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 2px;

      width: 95px;
      height: 36px;
      /* 品牌主题色 */
      background: #FF6026;
      /* 品牌主题色 */
      border: 1px solid #FF6026;
      border-radius: 6px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }

    .logo {
      width: 106.67px;
      height: 32px;
      margin-right: 80px;
    }

    .navbar-item {
      margin-right: 40px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 80px;
      color: #333333;
      cursor: pointer;
    }

    .active {
      color: #FF6026;
    }
  }

}

.banner {
  height: 880px;
  width: 100%;
  padding-top: 80px;
  background-size: contain;
  background-image: url("~@/assets/home/meber-bg-new.jpg");

  .banner-info {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 64px;
    box-sizing: border-box;

    .banner-title { /* 一站式包装采购服务平台 */
      height: 67px;
      font-family: 'Alimama ShuHeiTi';
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 67px;
      text-align: center;
      color: #333333;
    }

    .banner-desc {
      height: 28px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #525A6F;
      padding-top: 24px;
    }

  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    align-self: stretch;

    .card-item {
      cursor: pointer;
      width: 282px;
      height: 529px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      border-radius: 16px;
      padding: 44px 24px 0px 24px;

      .card-item-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .card-item-name {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 31px;
          /* identical to box height */
          text-align: center;
          color: #222529;
          padding: 16px 0px;
        }

        .card-item-tag {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */
          text-align: center;
          color: #7F8792;
        }

      }

      .card-item-price-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 48px;
        padding: 11px 0px 16px 0px;
        box-sizing: border-box;
      }

      .card-item-price {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #222529;
        line-height: 48px;
      }

      .card-item-unit {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #4C535C;
        padding: 22px 0px 8px 2px;
      }

      .card-btn {
        cursor: pointer;
        width: 198px;
        height: 40px;
        background: rgba(41, 88, 234, 0.12);
        border-radius: 6px;

        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */
        text-align: center;
        color: #2958EA;
        display: flex;
        align-items: center;
        justify-content: center;
        //margin-bottom: 226px;
      }

      .card-btn1 {
        background: #FFD173;
        color: #72440F;
        margin-bottom: 0px
      }

      .card-btn2 {
        color: #FFFFFF;
        background: linear-gradient(90deg, #0B0413 0%, #511B60 100%);
        margin-bottom: 0px

      }

      .card-btn3 {
        color: #F8CD94;
        background: linear-gradient(90deg, #191817 0%, #362411 100%);
        margin-bottom: 0px

      }
    }
  }
}

.line24 {
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 24px 0px;
  width: 234px;
}


.member-info {
  height: 21px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #222529;
  margin-bottom: 16px;
}

.member-tag-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.member-tag {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222529;
  gap: 8px;
  display: flex;
  align-items: center;

}

.memer-tip {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222529;
  padding: 8px 12px;
  background: #F1F2F4;
  border-radius: 4px;
  margin-bottom: 16px;
}

.float-card {
  margin-top: 435px;
  margin-right: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  position: absolute;
  background: #FEF8F6;
  border: 1px solid #F08D70;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  right: 0px;

  .float-title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
    color: #000000;

  }

  .float-desc {
    width: 180px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: #9C9A99;
  }

  .custer-delete-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
  }
}

.model-Member {
  width: 880px;

  .model-tabs {
    padding: 24px 24px 0px 24px;
    width: 100%;
    height: 100px;
    background: #333333;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    gap: 16px;

    .model-tab {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 49px;
      gap: 10px;
      width: 199px;
      height: 76px;
      background: #474747;
      border-radius: 8px 8px 0px 0px;

      .model-title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        width: 88px;

      }

      .model-desc { /* 适合个人短频使用 */
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #FFFFFF;
        width: 144px;
        text-align: center;
      }

      .model-min-img {
        width: 28px;
        height: 28px;
      }

    }

    .active {
      background: #FFFFFF;

      .model-desc {
        color: #666666;
      }

      .model-title {
        color: #333333;

      }
    }
  }

  .model-content, .model-content1, .model-content2 {
    width: 880px;
    height: 616px;
    background: #FFFFFF;
    border-radius: 0px 0px 16px 16px;

    .content-box {
      display: flex;
      height: 100%;

      .left-box, left-box1 {
        width: 206px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 56px 24px;
        background: linear-gradient(180deg, rgba(235, 241, 255, 0.5) 0%, #EBF1FF 100%);
        border-radius: 0px 0px 0px 16px;

        .left-item {
          display: flex;
          align-items: center;
          flex-direction: column;

          .left-item-title {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            /* identical to box height */
            text-align: center;
            color: #222529;
            padding: 16px 0px;
          }

          .left-item-desc {

            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            /* identical to box height, or 150% */
            text-align: center;
            color: #4D535B;
          }

          .left-item-ex-desc {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #7F8792;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            align-items: flex-start;
            width: 100%;

          }
        }

        img {
          width: 60px;
          height: 60px;
        }

        .left-box-line {
          width: 158px;
          height: 1px;
          background: #E9EAEC;
          margin-bottom: 24px;
          margin-top: 24px;
        }
      }

      .left-box, left-box1, left-box2 {
        background: linear-gradient(180deg, rgba(255, 244, 238, 0.5) 0%, #FFF4EE 100%);


        .member-img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        .left-item-ex-desc {
          text-align: start !important;

          .desc-title {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            //text-align: center;
            color: #4D535B;
          }

          img {
            width: 20px;
            height: 20px;
            padding-right: 8px;
          }

          .desc-ex {
            display: flex;
            align-items: center;
            gap: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #222529;
            padding-top: 16px;

            .desc-circle {
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background: #4D535B;
              margin: 7px 6px;
            }
          }
        }
      }

      .left-box2 {
        background: linear-gradient(180deg, rgba(244, 240, 254, 0.5) 0%, #F4F0FE 100%);
      }

      .center-box {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .center-top, center-top1 {
          display: flex;
          flex-direction: column;
          gap: 16px;
          //padding: 26px 24px 66px 24px;
          padding: 24px;

          .center-standing {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #666666;
            display: flex;
            align-items: center;
            gap: 4px;

            .center-standing-tip {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 4px;
              width: 56px;
              height: 25px;
              background: #EEEEEE;
              border-radius: 4px;
              color: #666666;
            }
          }

          .center-member-boxs {
            display: flex;
            height: 110px;
            gap: 24px;
          }

          .center-member-box, center-member-box-default {
            position: relative;
            width: 156px;
            height: 110px;
            //padding: 41.5px 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            background: #FFFFFF;
            /* 品牌主题色 */
            border: 1.5px solid #FF6026;
            border-radius: 8px;
            cursor: pointer;

            .member-name {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              /* 品牌主题色 */
              color: #FF6026;
            }

            .member-symbol {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              color: #FF6026;
              padding-bottom: 5px;
              padding-right: 5px;
            }

            .member-price {
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 39px;
              /* 品牌主题色 */
              color: #FF6026;
            }
          }

          .center-member-box-default {
            .member-symbol, .member-price, .member-name {
              color: #131212;
              font-weight: 500;
            }

            border: 1px solid #EAEBF0;
          }

          .center-privilege {
            width: 626px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 16px;
            background: rgba(255, 96, 38, 0.08);
            border-radius: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            height: 40px;
          }

          .center-privilege-item {
            display: flex;
            flex-wrap: wrap;

            .privilege-item {
              min-width: 144px;
              line-height: 22px;
              font-family: 'PingFang SC';
              font-weight: 600;
              font-size: 16px;
              font-style: normal;
              color: #333333;
            }

            img {
              width: 48px;
              height: 48px;
              margin-right: 16px;
            }

          }
        }

        .center-top1 {
          padding: 24px;
        }

        .member-referrer {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          //color: #666666;
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 320px;

          .member-referrer-left {
            width: 56px;
            color: #353535;
            margin-right: 16px;
            box-sizing: border-box;
            font-weight: 600
          }

          .member-referrer-right {
            display: flex;
            align-items: center;
            gap: 24px;

            .ex-payment {
              display: flex;
              align-items: center;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 160%;
              color: #FF6026;
              cursor: pointer;

              .ex-img {
                width: 12px;
                height: 12px;
                margin-left: 2px;
              }
            }

            .wx-payment {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 160%;

              color: #131212;

              position: relative;
              width: 92px;
              height: 40px;

              .wx-img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }

              .wx-inquiry {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 0px;
                bottom: 0px;
              }

              /* Frame 427320673 */

              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 9px 18px;
              /* 品牌主题色 */
              border: 1.5px solid #FF6026;
              border-radius: 6px;

            }

            .pay-symbol {
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              color: #FF6026;
              padding-bottom: 7px;
            }

            .pay-price {
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 700;
              font-size: 33px;
              line-height: 40px;
              color: #FF6026;
            }
          }
        }

        .pay-btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 8px;

          .pay-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            width: 240px;
            height: 38px;
            background: #FF6026;
            border-radius: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: #FFFFFF;

          }
        }

        .center-bottom {
          display: flex;
          background: #FFFFFF;
          border-top: 1px solid #EFEFEF;
          width: 626px;
          gap: 32px;
          margin: 0px 24px;
          padding-top: 24px;

          .bottom-item {
            cursor: pointer;
            gap: 8px;
            display: flex;
            align-items: center;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #666666;

          }
        }
      }

      .right-box {
        margin-top: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 80px 40px 24px;
        gap: 10px;
        background: #FFFFFF;
        border-left: 1px solid #EFEFEF;
        border-radius: 0px 0px 16px 0px;
        width: 260px;

        .right-top {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .right-top-syl {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #FF6026;
          padding-bottom: 7px;
          padding-right: 5px;
        }

        .right-top-price {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 46px;
          line-height: 56px;
          color: #FF6026;
        }

        .right-tag {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FF6026;
          padding-top: 16px;
          padding-bottom: 24px;
          display: flex;
          //align-items: center;
          cursor: pointer;

          .total-desc {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #131212;
            text-align: left;
          }

          .total-price {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            /* identical to box height, or 183% */
            color: #131212;
            display: flex;
            white-space: nowrap;
          }

          img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
          }
        }

        .collection-code {
          width: 180px;
          height: 180px;
        }

        .right-desc {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          color: #7F8792;
        }

        .forPublic-btn {
          cursor: pointer;
          box-sizing: border-box;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 48px;
          gap: 10px;
          margin: 0 auto;
          width: 180px;
          height: 34px;
          border: 1px solid #EFEFEF;
          border-radius: 6px;

          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          /* identical to box height, or 100% */
          text-align: center;

          color: #232529;
          white-space: nowrap;

        }
      }
    }
  }
}

.model-content1 {
  width: 1132px;
  height: 675px !important;
  background: #FFFFFF;
  border-radius: 0px 0px 16px 16px;


}

.model-content2 {
  width: 1132px;
  height: 731px !important;
  background: #FFFFFF;
  border-radius: 0px 0px 16px 16px;
}

.total-gift {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  color: #131212;

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-left: 0px !important;
  }

  .discount-price {

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* 品牌主题色 */
    color: #FF6026;

  }
}

.online-report {
  position: relative;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #301C0D;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 36px;
  border-radius: 4px;

  .report-desc {
    background: linear-gradient(90deg, rgba(255, 96, 38, 0.12) 0%, rgba(255, 255, 255, 0.1) 100%);
    padding: 8px 32px;
    width: 358px;
    position: absolute;
    margin-left: 12px;
  }

  img {
    width: 40px;
    height: 40px;
  }

}

.left-box3 {
  background: linear-gradient(180deg, rgba(254, 246, 233, 0.5) 0%, #FEF6E9 100%) !important;
}

.tip-desc {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  color: #7F8792;
}

.inquiry-type-img {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.info-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 16px 32px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .info-head {
    /* 16/加粗 */
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.85);

    padding-bottom: 16px;
    display: flex;
    align-items: center;
  }

  .info-desc {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* 辅助文字 */
    color: #8C8C8C;
    padding: 8px 0px 24px 0px;
    width: 472px;
  }

  .info-detail {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* 块背景 */
    background: #F7F8FA;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    padding: 24px;
    width: 332px;
    gap: 16px;

    .detail-row {
      width: 283px;
      display: flex;
      flex-direction: row;

      .detail-left {
        min-width: 84px;
        margin-right: 16px;
        /* 14/常规 */
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        /* or 22px */
        text-align: center;

        /* 文字色/标题+主要 */
        color: rgba(0, 0, 0, 0.85);
      }

      .detail-right {
        width: 100%;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        /* or 22px */
        color: rgba(0, 0, 0, 0.85);

      }
    }


  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .info-footer {
    padding: 24px 0px 16px 0px;
    display: flex;
    flex-direction: row;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .info-footer-desc {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8C8C8C;
      width: 474px;
    }
  }

  .detail-tag {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #FF6026;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    background: rgba(255, 96, 38, 0.1);
    border-radius: 4px;
  }

  .text {

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* 文字色/标题+主要 */
    color: rgba(0, 0, 0, 0.85);
  }

}

.member-close {
  position: absolute;
  width: 48px;
  height: 48px;
  right: -64px;
  top: -21px;
  cursor: pointer;
}

::v-deep .ant-modal-body {
  padding: 0px !important;
  border-radius: 16px 16px 0px 0px;
}

::v-deep .ant-modal-content {
  border-radius: 16px;
}

::v-deep .showContent .ant-modal-content {
  border-radius: 0px !important;
}

::v-deep .ant-tooltip-inner {
  color: #131212 !important;
  background-color: #ffffff !important;
}

::v-deep .ant-tooltip-arrow::before {
  background-color: #ffffff !important;
}

.meber-img {
  width: 16px;
  height: 16px;
}

.card-item:hover {
  mix-blend-mode: normal;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}

.collection-code-box {
  display: flex;
  flex-direction: column;
  padding: 40px 70px;

  .collection-code-desc {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #7F8792;
  }
}
</style>


